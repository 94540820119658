import RoutesEnum from '../../../enums/RoutesEnum';
import Row from '../../layout/Row';
import Section from '../../layout/Section';
import Container from '../../layout/Container';
import EverablePictureFrame from '../../common/pictureFrame/PictureFrame';
import SimpleAccordion from '../../common/accordion/Accordion';
import Button from '../../common/button/Button';
import upSkillingData from './constants/upSkillingData';
import { ReactComponent as UpSkillingImage } from './assets/up-skilling.svg';
import styles from './UpSkilling.module.scss';

const UpSkillingSection = () => (
    <Section sectionStyleClass={['margin40']}>
        <Row>
            <Container className='halfLarge'>

                <div data-testid='upskilling-text-wrapper' className={styles.upskillingWrapper}>
                    <h3 data-testid='upskilling-title' className={styles.title}>
                        {upSkillingData.title}
                    </h3>
                    <SimpleAccordion arrItem={upSkillingData.data} className='variant1' />
                    <Button buttonClass={['button']} to={RoutesEnum.REGISTER}>
                        Register Now
                    </Button>
                </div>

            </Container>
            <Container className="halfSmall">
                <div>
                    <EverablePictureFrame
                        frameClassName={['frame-540height', 'border-rounded-right-160px']}
                        image={(
                            <UpSkillingImage
                                data-testid='upskilling-image'
                                className={styles.upskillingImage}
                            />
                        )}
                        frameDescription={upSkillingData.title}
                    />
                </div>
            </Container>
        </Row>
    </Section>
);

export default UpSkillingSection;
