import {
    FC, ReactNode, useEffect, useState,
} from 'react';
import classNames from 'classnames';
import styles from './Section.module.scss';

export interface ISectionProps {
    gradientBlack?: boolean
    sectionStyleClass ?: string[],
    className?: 'content' | 'fullbleed',
    children: ReactNode
}

const Section : FC<ISectionProps> = ({
    className = 'content',
    sectionStyleClass = [],
    gradientBlack,
    children,
}) => {
    const [scrollColor, setScrollColor] = useState(false);

    const sectionStyle = sectionStyleClass.map(item => `${styles[item]}`);

    const listenScrollEvent = () => {
        if (window.scrollY > 10) {
            return setScrollColor(true);
        }

        return setScrollColor(false);
    };

    useEffect(() => {
        if (gradientBlack) {
            window.addEventListener('scroll', listenScrollEvent);
        }
        return () => window.removeEventListener('scroll', listenScrollEvent);
    }, [gradientBlack]);

    return (
        <section
            className={classNames(
                styles[className],
                sectionStyle,
                {
                    [styles.gradientBlack]: scrollColor,
                },
            )}
            data-testid='section'
        >
            {children}
        </section>
    );
};

export default Section;
