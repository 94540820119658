import { FC, ReactNode } from 'react';
import styles from './MainLayout.module.scss';
import Footer from '../Footer/Footer';

interface ILayoutProps{
    header: ReactNode,
    children: ReactNode
}

const MainLayout : FC<ILayoutProps> = ({ header, children }) => (
    <div className={styles.pageGrid} data-testid='layout'>
        {/* Pass in the header component, either small or large */}
        {header}
        {/* All the site content get inserted here */}
        {children}

        {/* Shared footer component only 1 variant in the designs and thus `static` render */}
        <Footer />
    </div>
);

export default MainLayout;
