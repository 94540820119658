import Divider from '@mui/material/Divider';
import Row from '../../layout/Row';
import Section from '../../layout/Section';
import Container from '../../layout/Container';
import Button from '../../common/button/Button';
import styles from './Fellowship.module.scss';
import RoutesEnum from '../../../enums/RoutesEnum';

const becomeFellowDescription = 'Everable courses are being developed in collaboration with subject matter experts. Our Fellows. Our lab factory enables highly effective co-creation of high quality content. Like to know more?';

const BecomeFellow = () => (
    <Section>
        <Row>
            <Container className='full'>
                <Divider className={styles.longDivider} />
                <div className={styles.becomeFellowSection}>
                    <h6 data-testid='become-fellow-title' className={styles.sectionTitle}>
                        BECOME A FELLOW
                    </h6>
                    <h6 data-testid='become-fellow-description' className={styles.sectionDescription}>
                        {becomeFellowDescription}
                    </h6>
                    <Button buttonClass={['button']} to={RoutesEnum.FELLOWSHIP}>
                        Fellowship
                    </Button>

                </div>
            </Container>
        </Row>

    </Section>
);

export default BecomeFellow;
