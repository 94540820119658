import IRequestInterceptors from '../../api/interfaces/IRequestInterceptors';
import TokenService from '../../token/services/TokenService';
import appendToken from '../utils/appendToken';

const createRequestInterceptors = (
    tokenService: TokenService,
) : IRequestInterceptors => ({
    onFulfilled: config => appendToken(config, tokenService.token),
    onRejected: error => error,
});

export default createRequestInterceptors;
