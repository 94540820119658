import Button from '@mui/material/Button';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import styles from './Button.module.scss';

const EverableButton = ({
    children, buttonClass, to, btnFullWidth,
}) => {
    const btnClass = buttonClass.map(item => `${styles[item]}`);

    return (
        <Button
            className={classNames(
                styles.button, btnClass, {
                    [styles.btnFullWidth]: btnFullWidth,
                },
            )}
            component={to.startsWith('/') ? RouterLink : 'a'}
            to={to}
            href={to}
            // Disable MUI ripple effect on click
            disableRipple
        >
            <p>{children}</p>
        </Button>
    );
};

EverableButton.propTypes = {
    children: PropTypes.string.isRequired,
    buttonClass: PropTypes.arrayOf(
        PropTypes.oneOf(['button', 'small', 'medium', 'outlined', 'inverted', 'outlinedWhite', 'contactUsButton']), // It only accepts buttonClasss declared in the stylesheet
    ).isRequired,
    btnFullWidth: PropTypes.bool,
    to: PropTypes.string.isRequired,
};

EverableButton.defaultProps = {
    btnFullWidth: false,
};

export default EverableButton;
