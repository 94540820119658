import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import IRequestInterceptors from '../interfaces/IRequestInterceptors';
import IResponseInterceptors from '../interfaces/IResponseInterceptors';
import statusChecker from '../utils/statusChecker';

export default class ApiService {
    private _httpClient: AxiosInstance;

    constructor(
        httpClient: AxiosInstance,
        requestInterceptors?: IRequestInterceptors,
        responseInterceptors?: IResponseInterceptors,
    ) {
        this._httpClient = httpClient;
        if (requestInterceptors) {
            this._httpClient.interceptors.request.use(
                requestInterceptors.onFulfilled,
                requestInterceptors.onRejected,
            );
        }
        if (responseInterceptors) {
            this._httpClient.interceptors.response.use(
                responseInterceptors.onFulfilled,
                responseInterceptors.onRejected,
            );
        }
    }

    async get<T = unknown>(
        url: string,
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse<T>> {
        return statusChecker(await this._httpClient.get<T>(url, config));
    }

    async post<T = unknown, B = unknown>(
        url: string,
        body: B,
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse<T>> {
        return statusChecker(await this._httpClient.post<T>(url, body, config));
    }

    async put<T = unknown, B = unknown>(
        url: string,
        body: B,
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse<T>> {
        return statusChecker(await this._httpClient.put<T>(url, body, config));
    }

    async delete(url: string): Promise<AxiosResponse<null>> {
        return statusChecker(await this._httpClient.delete(url));
    }
}
