import Row from '../../layout/Row';
import RoutesEnum from '../../../enums/RoutesEnum';
import Section from '../../layout/Section';
import Container from '../../layout/Container';
import EverablePictureFrame from '../../common/pictureFrame/PictureFrame';
import Button from '../../common/button/Button';
import EverableListItem from '../../common/listElement/ListElement';
import versatileData from './constants/versatile';
import { ReactComponent as VersatileImage } from './assets/versatile.svg';
import styles from './Versatile.module.scss';

const VersatileSection = () => (
    <Section sectionStyleClass={['margin40']}>
        <Row>
            <Container className="halfSmall">
                <EverablePictureFrame
                    frameClassName={['frame-540height', 'border-rounded-left-160px']}
                    image={(
                        <VersatileImage
                            data-testid='versatile-image'
                            className={styles.VersatileImage}
                        />
                    )}
                    frameDescription={versatileData.title}
                />
            </Container>
            <Container className='halfLarge'>

                <div data-testid='versatile-text-wrapper' className={styles.versatileWrapper}>
                    <h4 data-testid='versatile-title' className={styles.title}>
                        {versatileData.title}
                    </h4>
                    <p data-testid='versatile-subTitle' className={styles.subTitle}>
                        {versatileData.description}
                    </p>
                    <EverableListItem shapeIcon='checkCircleOutlineIcon' listData={versatileData.data} listItemStyle={['primaryBackground', 'zeroLeftPadding', 'mediumVerticalPadding', 'smallVerticalPadding']} />
                    <Button buttonClass={['button']} to={RoutesEnum.REGISTER}>
                        Register Now
                    </Button>
                </div>

            </Container>
        </Row>
    </Section>
);

export default VersatileSection;
