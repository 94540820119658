import RoutesEnum from '../../../../enums/RoutesEnum';

const links = [
    { to: RoutesEnum.ABOUT_US, label: 'About us' },
    {
        label: 'Knowledge',
        subMenu: [
            { subTo: RoutesEnum.COURSES, subLabel: 'Courses' },
            { subTo: RoutesEnum.INTRODUCTION_TOUR, subLabel: 'Introduction tour' },
            { subTo: RoutesEnum.LEARNING_PATHS, subLabel: 'Learning paths' },
        ],
    },
    { to: RoutesEnum.FELLOWSHIP, label: 'Fellowship' },
    { to: RoutesEnum.SOLUTIONS, label: 'Solutions' },
    { to: RoutesEnum.NEWS, label: 'News' },
];

export default links;
