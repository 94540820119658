import { FC } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useConfig } from '../../../core/config/contexts/ConfigContext';

export interface IGoogleRecaptchaProps{
    onChange: (token: string | null) => void;
    className?: string
}

const GoogleRecaptcha : FC<IGoogleRecaptchaProps> = ({
    onChange,
    className,
}) => {
    const config = useConfig();

    return (
        <div
            className={className}
            data-testid='google_recaptcha'
        >
            <ReCAPTCHA
                sitekey={config.reCaptchaSiteKey || ''}
                onChange={onChange}
            />
        </div>
    );
};
export default GoogleRecaptcha;
