import RoutesEnum from '../../../enums/RoutesEnum';
import Section from '../../layout/Section';
import CallToActionBanner from '../../common/actionBanner/CallToActionBanner';

const CallToActionSection = () => (
    <Section>
        <CallToActionBanner buttonText='Register now' buttonPathName={RoutesEnum.REGISTER}>
            Start the free introduction tour to determine Secure DevOps learning paths that benefit your teams
        </CallToActionBanner>
    </Section>
);

export default CallToActionSection;
