import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import CustomLink from '../../common/link/Link';
import styles from './Fellowship.module.scss';

const FellowStory = ({ fellowData }) => (
    <div data-testid='fellow-story-container' className={styles.fellowshipSectionLeftContainer}>
        <h6 data-testid='fellow-quote' className={styles.quote}>
            &#8221;
            {fellowData.quote}
            &#8221;
        </h6>
        <p data-testid='fellow-description' className={styles.description}>
            {fellowData.description}
        </p>
        <CustomLink className={styles.link} href="/story">How Everable helped me</CustomLink>
        <Divider data-testid='divider' className={styles.smallDivider} />
        <h6 data-testid='fellow-name' className={styles.name}>{fellowData.name}</h6>
        <p data-testid='fellow-title' className={styles.title}>{fellowData.title}</p>
    </div>
);

FellowStory.propTypes = {
    fellowData: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        quote: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
    }).isRequired,
};

export default FellowStory;
