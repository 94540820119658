import RoutesEnum from '../../../../enums/RoutesEnum';

const platform = [
    { to: RoutesEnum.COURSES, label: 'Courses' },
    { to: RoutesEnum.LEARNING_PATHS, label: 'Learning paths' },
    { to: RoutesEnum.INTRODUCTION_TOUR, label: 'Introduction tour' },
    { to: RoutesEnum.FELLOW_GALLERY, label: 'Fellow Gallery' },

];

export default platform;
