import PropTypes from 'prop-types';
import styles from './Testimonials.module.scss';

const CarouselItem = ({
    comment, name, title, userImage,
}) => (
    <div className={styles.cardWrapper} data-testid='carousel-item-container'>
        <p className={styles.userComment}>{comment}</p>
        <div className={styles.userData} data-testid='carousel-user-container'>
            <div className={styles.userImage}>
                <img src={userImage} alt='avatar' />
            </div>
            <div className={styles.userInfo}>
                <p>{name}</p>
                <span>{title}</span>
            </div>
        </div>
    </div>
);

CarouselItem.propTypes = {
    comment: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    userImage: PropTypes.string.isRequired,
};

export default CarouselItem;
