import fellow1 from './fellow1.png';
import fellow2 from './fellow2.png';

const fellowData = [
    {
        id: 1,
        name: 'SARAH POLAN',
        title: 'Secrets Management Architect, Everable Fellow',
        quote: 'I wanted to try different things, which led me to expand my horizons and enter the world of IT and become interested in cybersecurity.',
        description: 'After gaining experience in the IT industry I got the opportunity to work with Araido, a DevSecOps consulting company. Araido’s partner company, the Everable helped me to understand and apply Secure DevOps concepts through their hands-on courses.This helped me to become specialized in Secrets Management. I was even able to apply this knowledge by designing a Secrets Management Solution for ABN AMRO and provide training on it. This led to my current role as Secrets Management Architect at ABN AMRO.',
        image: fellow1,
    },
    {
        id: 2,
        name: 'DENNIS MARKISH',
        title: 'Cloud Security Consultant',
        quote: 'To stay informed with the latest security approaches, I’m always striving to cover new areas and keep up with trends.',
        description: 'The world’s large-scale adoption of cloud computing and DevOps processes made it important for me to gain experience in these areas. Everable’s hands-on learning paths help me to remain relevant for high profile assignments and challenges. This ongoing training has helped me to become a credible choice for companies’ assignments to improve their Secure DevOps practices, particularly with regard to their cloud security. What I value is the efficient nature of the courses which you can finish in one day.',
        image: fellow2,
    },

];

export default fellowData;
