import FellowshipSection from '../../components/landing-page/FellowshipSection/Fellowship';
import VersatileSection from '../../components/landing-page/VersatileSection/Versatile';
import CallToActionSection from '../../components/landing-page/CallToActionSection/CallToAction';
import MainHeader from '../../components/layout/MainHeader/MainHeader';
import Testimonials from '../../components/landing-page/TestimonialsSection';
import UpSkillingSection from '../../components/landing-page/SkillingSection/UpSkilling';
import NewsSection from '../../components/landing-page/newsSection';

const Home = () => (
    <>
        <MainHeader leftShape homeText homePageImage />
        <UpSkillingSection />
        <Testimonials />
        <VersatileSection />
        <FellowshipSection />
        <NewsSection />
        <CallToActionSection />
    </>
);

export default Home;
