import SimpleCarousel from '../../common/carousel/Carousel';
import Subheading from '../../common/subheading';
import Section from '../../layout/Section';
import CarouselItem from './CarouselItem';
import testimonialData from './constants/testimonials';
import styles from './Testimonials.module.scss';

const Testimonials = () => (
    <Section sectionStyleClass={['margin40']}>
        <SimpleCarousel
            header={(
                <>
                    <Subheading className={styles.title} size='small'>
                        TESTIMONIALS
                    </Subheading>
                    <h4 className={styles.subTitle}>
                        What People Say
                    </h4>
                </>
            )}
        >
            {
                testimonialData.map(item => (
                    <CarouselItem
                        key={item.id}
                        comment={item.summary}
                        name={item.name}
                        title={item.title}
                        userImage={item.image}
                    />
                ))
            }
        </SimpleCarousel>
    </Section>
);

export default Testimonials;
