import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider } from '@mui/material/styles';
import { StrictMode } from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import App from './App';
import { ApiServiceProvider } from './core/api/contexts/ApiServiceContext';
import ApiService from './core/api/services/ApiService';
import createHttpClient from './core/api/utils/createHttpClient';
import loadConfig from './core/config/utils/loadConfig';
import { ConfigProvider } from './core/config/contexts/ConfigContext';
import createRequestInterceptors from './core/session/interceptors/createRequestInterceptors';
import createResponseInterceptors from './core/session/interceptors/createResponseInterceptors';
import SessionApiService from './core/session/services/SessionApiService';
import rootReducer from './core/store/reducers/rootReducer';
import TokenService from './core/token/services/TokenService';

// eslint-disable-next-line no-process-env
const config = loadConfig(process.env);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk)),
);

const tokenService = new TokenService();
// eslint-disable-next-line no-process-env
const axiosClient = createHttpClient(config.apiBaseUrl);
const sessionApiService = new SessionApiService(axiosClient);
const apiService = new ApiService(
    axiosClient,
    createRequestInterceptors(tokenService),
    createResponseInterceptors(tokenService, sessionApiService),
);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60 * 60 * 24,
        },
    },
});

ReactDOM.render(
    <StrictMode>
        <ConfigProvider config={config}>
            <CookiesProvider>
                <ApiServiceProvider apiService={apiService}>
                    <QueryClientProvider client={queryClient}>
                        <Provider store={store}>
                            <CssBaseline />
                            <StyledEngineProvider injectFirst>
                                <App />
                            </StyledEngineProvider>
                        </Provider>
                    </QueryClientProvider>
                </ApiServiceProvider>
            </CookiesProvider>
        </ConfigProvider>
    </StrictMode>,
    document.getElementById('root'),
);
