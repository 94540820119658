import { AxiosInstance } from 'axios';
import ApiService from '../../api/services/ApiService';
import IToken from '../../token/interfaces/IToken';

export default class SessionApiService extends ApiService {
    constructor(httpClient: AxiosInstance) {
        super(httpClient, undefined, undefined);
    }

    async refreshToken(refreshToken: string): Promise<IToken> {
        const {
            data: { token, refreshToken: newRefreshToken },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } = await super.post<IToken, any>('/token', {
            refreshToken,
        });

        return {
            token,
            refreshToken: newRefreshToken,
        };
    }
}
