import PropTypes from 'prop-types';
import Logo from '../../../../components/common/logo/Logo';
import PictureFrame from '../../../../components/common/pictureFrame';
import Placeholder from '../../../../components/common/placeholder/Placeholder';
import styles from './ContactInfoItem.module.scss';

const ContactInfoItem = ({
    image,
    imageDescription,
    position,
    email,
    phone,
}) => (
    <div className={styles.contactInformationContainer}>
        {
            image
                ? (
                    <PictureFrame
                        image={image}
                        frameClassName={['transparent-ground', 'contactAvatarFrame']}
                        imageClassName='contactAvatarImageFrame'
                        frameDescription={imageDescription}
                    />
                )
                : (
                    <Placeholder
                        className='contactAvatarPlaceholder'
                    >
                        <Logo type='graphic' graphicColor='white' width={32} />
                    </Placeholder>
                )
        }
        <div className={styles.textContentWrapper}>
            <p>{position}</p>
            <a href={`mailto:${email}`}>{email}</a>
            <a href={`tel:${phone}`}>{phone}</a>
        </div>
    </div>
);

ContactInfoItem.defaultProps = {
    image: '',
    imageDescription: '',
};

ContactInfoItem.propTypes = {
    image: PropTypes.string,
    imageDescription: PropTypes.string,
    position: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
};

export default ContactInfoItem;
