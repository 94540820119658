import PropTypes from 'prop-types';
import React from 'react';
import Button from '../button/Button';
import styles from './CallToActionBanner.module.scss';
import { ReactComponent as BannerLeftShape } from './assets/logo-top-right-corner.svg';
import { ReactComponent as BannerRightShape } from './assets/logo-top-left-corner.svg';

const CallToActionBanner = ({ children, buttonText, buttonPathName }) => (

    <div
        className={`${styles.bannerContainer}`}
        data-testid='call-action-container'
    >

        <BannerLeftShape
            className={styles.bannerShape}
            data-testid='banner-left-shape'
        />
        <BannerRightShape
            className={styles.bannerShape}
            data-testid='banner-right-shape'
        />

        {/* Banner Content */}
        <h6
            data-testid='banner-content'
        >
            {children}
        </h6>

        {/* Banner Call to Action Button */}
        <div
            className="banner-btn"
            data-testid='banner-button'
        >
            <Button buttonClass={['button', 'inverted']} to={buttonPathName}>
                {buttonText}
            </Button>
        </div>

    </div>
);

CallToActionBanner.propTypes = {
    children: PropTypes.node.isRequired,
    buttonText: PropTypes.string.isRequired,
    buttonPathName: PropTypes.string.isRequired,
};

export default CallToActionBanner;
