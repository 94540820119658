import React, { useState, Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PropTypes from 'prop-types';
import NavLink from '../navLink/NavLink';
import styles from './NavMenu.module.scss';

const NavMenu = ({
    className, links, vertical,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <nav className={`${styles.nav} ${vertical && styles.vertical}`} data-testid='navigation'>
            {links.map(item => {
                if (item.subMenu) {
                    return (
                        <Fragment key={`${item.to}${item.label}`}>
                            <Button
                                id={`btn-${item.label.replace(/\s+/g, '')}`}
                                disableRipple
                                className={styles[className]}
                                endIcon={<KeyboardArrowDownIcon />}
                                aria-controls={open ? '' : undefined}
                                aria-haspopup
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                type='button'
                            >
                                {item.label}
                            </Button>
                            <Menu
                                PaperProps={{ className: styles.menuPaper }}
                                MenuListProps={{
                                    'aria-labelledby': `btn-${item.label.replace(/\s+/g, '')}`,
                                }}
                                anchorEl={anchorEl}
                                elevation={0}
                                open={open}
                                onClose={handleClose}
                            >
                                {item.subMenu?.map(sub => (
                                    <MenuItem
                                        key={`${sub.subTo}_${sub.subLabel}`}
                                        component={sub.subTo.startsWith('/') ? RouterLink : 'a'}
                                        onClick={handleClose}
                                        to={sub.subTo}
                                        href={sub.subTo}
                                        disableRipple
                                        className={styles.menuitem}
                                    >
                                        {sub.subLabel}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Fragment>
                    );
                }
                return <NavLink key={`${item.to}${item.label}`} to={item.to} label={item.label} className={className} />;
            })}
        </nav>
    );
};

NavMenu.propTypes = {
    className: PropTypes.oneOf(['text-white', 'text-black']),
    links: PropTypes.arrayOf(PropTypes.shape({
        to: PropTypes.string,
        label: PropTypes.string.isRequired,
        subMenu: PropTypes.arrayOf(PropTypes.shape({
            subTo: PropTypes.string,
            subLabel: PropTypes.string,
        })),
    })).isRequired,
    vertical: PropTypes.bool,
};

NavMenu.defaultProps = {
    className: 'text-white',
    vertical: false,
};

export default NavMenu;
