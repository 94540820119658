import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './PictureFrame.module.scss';
import { ReactComponent as AccentShape } from './assets/logo-top-left-corner.svg';

export interface IPictureFrameProps {
    image: string | ReactNode,
    frameClassName? : string[],
    imageClassName?: string,
    accentShape?: boolean,
    accentClassName?: string,
    frameDescription?: string,
}

const PictureFrame: FC<IPictureFrameProps> = ({
    image,
    frameClassName = [],
    imageClassName = '',
    accentShape,
    accentClassName = '',
    frameDescription = '',
}) => {
    const frameStyle = frameClassName.map(item => `${styles[item]}`);

    return (
        <div
            className={classNames(styles.mainPictureFrame, frameStyle)}
            data-testid='picture-frame-container'
        >
            {typeof image === 'string'
                ? (
                    <img
                        className={styles[imageClassName]}
                        data-testid='main-frame-picture'
                        src={image}
                        alt={frameDescription}
                    />
                ) : image}

            {accentShape
        && (
            <AccentShape
                data-testid='frame-accent-shape'
                className={styles[accentClassName]}
            />
        )}
        </div>
    );
};

export default PictureFrame;
