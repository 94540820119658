import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
    Collapse, List, ListItemButton, ListItemText,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import styles from './MainMenu.module.scss';

const MobileMenu = ({
    links,
}) => {
    const [openSubMenu, setOpenSubMenu] = useState(false);

    const handleSubMenuClick = () => {
        setOpenSubMenu(!openSubMenu);
    };
    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={styles.menuMobile}
        >

            {links.map(item => {
                if (item.subMenu) {
                    return (
                        <>
                            <ListItemButton
                                className={styles.listBtn}
                                key={`${item.to}_${item.label}`}
                                onClick={handleSubMenuClick}
                                data-testid='list-item-btn'

                            >
                                <ListItemText primary={item.label} />
                                {openSubMenu ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={openSubMenu} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {item.subMenu?.map(sub => (
                                        <ListItemButton
                                            className={styles.listSubMenuBtn}
                                            key={`${sub.subTo}_${sub.subLabel}`}
                                            component={sub.subTo.startsWith('/') ? RouterLink : 'a'}
                                            to={sub.subTo}
                                            href={sub.subTo}
                                            data-testid='list-subItem-btn'
                                        >

                                            <ListItemText className={styles.subMenuBtn} primary={sub.subLabel} />
                                        </ListItemButton>
                                    ))}

                                </List>
                            </Collapse>
                        </>
                    );
                }
                return (
                    <ListItemButton
                        className={styles.listBtn}
                        key={`${item.to}_${item.label}`}
                        component={item.to.startsWith('/') ? RouterLink : 'a'}
                        to={item.to}
                        href={item.to}
                        data-testid='list-item-btn'
                    >
                        <ListItemText primary={item.label} />
                    </ListItemButton>
                );
            })}
        </List>

    );
};

MobileMenu.propTypes = {
    links: PropTypes.arrayOf(PropTypes.shape({
        to: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        subMenu: PropTypes.arrayOf(PropTypes.shape({
            subTo: PropTypes.string.isRequired,
            subLabel: PropTypes.string.isRequired,
        })),
    })).isRequired,
};
export default MobileMenu;
