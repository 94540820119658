import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import styles from './Chip.module.scss';

export interface IChipProps{
children?: ReactNode;
className?: string;
}

const Chip: FC<IChipProps> = ({ children, className }) => (
    <p className={classNames(styles.chip, className)}>
        {children}
    </p>
);

export default Chip;
