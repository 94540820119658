import React from 'react';
import PropTypes from 'prop-types';

import Link from '@mui/material/Link';
import colors from '../../../styles/colors.scss';
import fontSize from '../../../styles/typography.scss';

const linkStyle = {
    color: colors.primaryBlue,
    fontSize: fontSize.fontSize18,
    cursor: 'pointer',
};

const CustomLink = ({ children, href }) => (
    <Link style={linkStyle} href={href} underline="hover">
        {children}
    </Link>
);

export default CustomLink;

CustomLink.propTypes = {
    children: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
};
