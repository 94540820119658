const {
    REACT_APP_PLATFORM_BASE_URL,
    REACT_APP_HUBSPOT_BASE_URL,
} = process.env; // eslint-disable-line no-process-env

const RoutesEnum = {
    DEFAULT: '/',
    DEMO: '/demo',
    LOGIN: `${REACT_APP_PLATFORM_BASE_URL}/login`,
    REGISTER: `${REACT_APP_PLATFORM_BASE_URL}/register`,
    CONTACT: '/contact-us',
    ABOUT_US: `${REACT_APP_HUBSPOT_BASE_URL}/about-us`,
    FELLOWSHIP: `${REACT_APP_HUBSPOT_BASE_URL}/fellowship`,
    SOLUTIONS: `${REACT_APP_HUBSPOT_BASE_URL}/solutions`,
    FOR_BUSINESS: `${REACT_APP_HUBSPOT_BASE_URL}/for-business`,
    NEWS_OVERVIEW: `${REACT_APP_HUBSPOT_BASE_URL}/news`,
    NEWS: `${REACT_APP_HUBSPOT_BASE_URL}/news`,
    NEWS_POST: `${REACT_APP_HUBSPOT_BASE_URL}/news/post`,
    NEWS_ACADEMY: `${REACT_APP_HUBSPOT_BASE_URL}/news/academy`,
    NEWS_COMMUNITY: `${REACT_APP_HUBSPOT_BASE_URL}/news/community`,
    CAREERS: '/careers',
    COURSES: `${REACT_APP_PLATFORM_BASE_URL}/courses`,
    INTRODUCTION_TOUR: `${REACT_APP_PLATFORM_BASE_URL}/academy-tour`,
    LEARNING_PATHS: `${REACT_APP_PLATFORM_BASE_URL}/learning-paths`,
    FELLOW_GALLERY: `${REACT_APP_HUBSPOT_BASE_URL}/fellow_gallery`,
    PRIVACY: `${REACT_APP_PLATFORM_BASE_URL}/policy`,
    TERMS_OF_USE: `${REACT_APP_PLATFORM_BASE_URL}/policy`,
    COOKIES: `${REACT_APP_PLATFORM_BASE_URL}/policy`,
};

export default RoutesEnum;
