import {
    Button,
    Checkbox,
    FormControlLabel,
    TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import classNames from 'classnames';
import { useState } from 'react';
import * as yup from 'yup';
import ErrorMessage from '../../../../components/common/ErrorMessage';
import GoogleRecaptcha from '../../../../components/common/googleRecaptcha';
import CustomLink from '../../../../components/common/link/Link';
import { useApiService } from '../../../../core/api/contexts/ApiServiceContext';
import styles from './ContactForm.module.scss';

const initialState = {
    name: '',
    email: '',
    phone: '',
    message: '',
    privacy: false,
    recaptchaKey: '',
};

const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g;

const schema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().matches(emailRegExp, 'Email is not valid'),
    phone: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    message: yup.string().required(),
    privacy: yup.boolean().oneOf([true]).required(),
    recaptchaKey: yup.string().required(),
});

const ContactForm = () => {
    const [data, setData] = useState(initialState);
    const [errors, setErrors] = useState({});
    const apiService = useApiService();

    const onChange = e => {
        switch (e.target.name) {
        case 'privacy':
            setData({
                ...data,
                [e.target.name]: e.target.checked,
            });
            break;
        case 'phone':
            setData({
                ...data,
                [e.target.name]: phoneRegExp.test(e.target.value) ? e.target.value : data[e.target.name],
            });
            break;
        default:
            setData({
                ...data,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleCaptchaChange = e => {
        if (typeof e === 'string') setData({ ...data, recaptchaKey: e });
        else setData({ ...data, recaptchaKey: '' });
    };

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            await schema.validate(data, { abortEarly: false });
            const res = await apiService.post('/api/v1/send-contact-form', data);
            // eslint-disable-next-line no-console
            console.log('API RES', res);
        } catch (error) {
            const newErrors = {};
            error?.inner?.forEach(({ path, message }) => { newErrors[path] = message; });
            setErrors(newErrors);
            // eslint-disable-next-line no-console
            console.log('ERROR', { error });
        }
    };

    return (
        <Box
            component="form"
            autoComplete
            onSubmit={handleSubmit}
            className={styles.formBox}
            data-testid="contact-box"
        >
            <p className={styles.formText}>Fill out the form below and we will get back to you.</p>
            <TextField
                required
                placeholder="Name*"
                name='name'
                onChange={onChange}
                value={data.name}
            />
            <ErrorMessage show={errors.name}>{errors.name}</ErrorMessage>
            <TextField
                required
                placeholder="E-mail*"
                name='email'
                onChange={onChange}
                value={data.email}
            />
            <ErrorMessage show={errors.email}>{errors.email}</ErrorMessage>
            <TextField
                required
                placeholder="Phone*"
                name='phone'
                onChange={onChange}
                value={data.phone}
                type="number"
            />
            <ErrorMessage show={errors.phone}>{errors.phone}</ErrorMessage>
            <TextField
                required
                placeholder='Message*'
                className={styles.textArea}
                name='message'
                onChange={onChange}
                value={data.message}
                multiline
                rows={5}
            />
            <ErrorMessage show={errors.message}>{errors.message}</ErrorMessage>
            <FormControlLabel
                className={styles.formControl}
                name='privacy'
                onChange={onChange}
                checked={data.privacy}
                data-testid='checkbox'
                control={(
                    <Checkbox
                        required
                        className={styles.formCheckbox}
                        icon={<div className={styles.checkboxIcon} />}
                        checkedIcon={(
                            <div className={classNames(styles.checkboxIcon, styles.checkboxChecked)}>
                                <svg viewBox="0 0 32 32">
                                    <path d="M27 4l-15 15-7-7-5 5 12 12 20-20z" />
                                </svg>
                            </div>
                        )}
                    />
                )}
                label={(
                    <div>
                        <span>
                            By filling out this form and clicking submit, you acknowledge our
                            {' '}
                            <CustomLink href='/'>Privacy policy</CustomLink>
                            .
                        </span>
                    </div>
                )}
            />
            <ErrorMessage show={errors.privacy}>{errors.privacy}</ErrorMessage>
            <GoogleRecaptcha onChange={handleCaptchaChange} />
            <Button type='submit' className={styles.button}>Submit form</Button>
        </Box>
    );
};
export default ContactForm;
