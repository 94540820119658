import Row from '../../layout/Row';
import Section from '../../layout/Section';
import Container from '../../layout/Container';
import EverablePictureFrame from '../../common/pictureFrame/PictureFrame';
import FellowStory from './FellowStory';
import BecomeFellow from './BecomeFellow';
import fellowData from './assets/fellowData';
import styles from './Fellowship.module.scss';

const FellowshipSection = () => (
    <Section
        className='fullbleed'
        sectionStyleClass={['padding136']}
    >
        <Section>
            <Row>
                <Container className='half'>
                    <div className={styles.fellowshipSectionHeading}>
                        <h6 data-testid='fellowship-section-title' className={styles.sectionTitle}>
                            FELLOWSHIP
                        </h6>
                        <h3 data-testid='fellowship-section-subtitle' className={styles.subTitle}>
                            Story’s from our fellows worth sharing
                        </h3>
                    </div>
                </Container>
            </Row>
            <Row className='fellowSection-37padding'>

                <Container className='halfLarge'>
                    <FellowStory fellowData={fellowData[0]} />
                </Container>
                <Container className='halfSmall'>
                    <div>
                        <EverablePictureFrame
                            frameClassName={['frame-520height', 'frame-460width', 'transparent-ground', 'position-right']}
                            image={fellowData[0].image}
                            imageClassName='img-right-rounded'
                            frameDescription={fellowData[0].name}
                        />
                    </div>
                </Container>
            </Row>
            <Row className='fellowSection-displayNone'>

                <Container className='halfSmall'>
                    <div>
                        <EverablePictureFrame
                            frameClassName={['frame-520height', 'frame-460width', 'transparent-ground']}
                            image={fellowData[1].image}
                            imageClassName='img-left-rounded'
                            frameDescription={fellowData[1].name}
                        />
                    </div>
                </Container>
                <Container className='halfLarge'>
                    <FellowStory fellowData={fellowData[1]} />
                </Container>
            </Row>
        </Section>
        <BecomeFellow />

    </Section>
);

export default FellowshipSection;
