import { useQuery } from 'react-query';
import { useApiService } from '../../../../core/api/contexts/ApiServiceContext';
import INewsResponse from '../interfaces/INewsResponse';

const useNews = () => {
    const apiService = useApiService();

    const {
        data,
        isLoading,
        isSuccess,
        isFetched,
    } = useQuery(
        'news',
        () => apiService?.get<INewsResponse>('/api/v1/get-home-news'),
    );

    return {
        news: data?.data || { academyNews: [], communityNews: [] },
        isLoading,
        isSuccess,
        isFetched,
    };
};

export default useNews;
