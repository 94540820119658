import avatar2 from '../assets/avatar2.png';

const contactData = [
    {
        id: 1,
        image: '',
        imageDescription: '',
        position: 'Support',
        email: 'support@everable.com',
        phone: '1234 5678990',
    },
    {
        id: 2,
        image: avatar2,
        imageDescription: 'avatar',
        position: 'Sales',
        email: 'sales@everable.com',
        phone: '1234 5678990',
    },
];
export default contactData;
