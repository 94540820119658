import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Container.module.scss';

export interface IContainerProps{
    className?: 'full' | 'half' | 'third' | 'quart' | 'fifth' | 'halfRowDirection' | 'twoThird',
    children: ReactNode,
    displayNoneMobile?: boolean
}

const Container : FC<IContainerProps> = ({
    className = 'full',
    children,
    displayNoneMobile,
}) => (
    <div
        className={classNames(
            styles.base,
            styles[className], {
                [styles.displayNoneMobile]: displayNoneMobile,
            },
        )}
        data-testid='container'
    >
        {children}
    </div>
);

export default Container;
