import { FC, ReactNode } from 'react';
import ElasticCarousel from 'react-elastic-carousel';
import classNames from 'classnames';
import breakPoints from './config';
import styles from './Carousel.module.scss';
import './carousel-override.scss';

export interface ISimpleCarouselProps{
    header: ReactNode,
    className?: string
}

const Carousel : FC<ISimpleCarouselProps> = ({
    header,
    children,
    className,
}) => (
    <div
        className={classNames(styles.carousel_wrapper, className)}
        data-testid='carousel-container'
    >
        {header}
        <ElasticCarousel
            isRTL={false}
            breakPoints={breakPoints}
            enableAutoPlay
            showArrows={false}
            transitionMs={1500}
            autoPlaySpeed={3000}
        >
            {children}
        </ElasticCarousel>
    </div>
);

export default Carousel;
