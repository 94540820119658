import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

import styles from './NavLink.module.scss';

const NavLink = ({ to, label, className }) => (
    <Button
        disableRipple
        component={to.startsWith('/') ? RouterLink : 'a'}
        to={to}
        href={to}
        className={styles[className]}
    >
        {label}
    </Button>
);

NavLink.propTypes = {
    className: PropTypes.oneOf(['text-white', 'text-black', 'privacy-footer']).isRequired,
    to: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default NavLink;
