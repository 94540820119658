const getFromLocalStorage = <T>(key: string): T | null => {
    const item = window.localStorage.getItem(key);

    if (item !== null) {
        try {
            const parsed = JSON.parse(item);
            return parsed || item;
        } catch (error) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return item as any;
        }
    }
    return null;
};

export default getFromLocalStorage;
