import { FC } from 'react';
import classNames from 'classnames';
import RoutesEnum from '../../../enums/RoutesEnum';
import Section from '../Section';
import Row from '../Row';
import Container from '../Container';
import mainHeaderData from './constants/mainHeader';
import Button from '../../common/button/Button';
import { ReactComponent as LeftShape } from './assets/left-shape.svg';
import { ReactComponent as RightShape } from './assets/right-shape.svg';
import { ReactComponent as HomeImage } from './assets/home-image.svg';
import styles from './MainHeader.module.scss';

export interface IMainHeaderProps {
    headerClassName? : string[],
    headerText?: string,
    leftShape?: boolean,
    rightShape?: boolean,
    homeText?: boolean,
    homePageImage?: boolean,

}

const MainHeader: FC<IMainHeaderProps> = ({
    headerClassName = [],
    headerText = '',
    leftShape,
    rightShape,
    homeText,
    homePageImage,
}) => {
    const headerStyle = headerClassName.map(item => `${styles[item]}`);

    return (
        <div
            className={classNames(styles.base, headerStyle)}
            data-testid='main-header-container'
        >
            {leftShape
            && (
                <LeftShape
                    className={styles.leftShape}
                    data-testid='header-left-shape'
                />
            )}

            {rightShape
            && (
                <RightShape
                    className={styles.rightShape}
                    data-testid='header-right-shape'
                />
            )}

            <Section>
                <Row>
                    {homeText
                        && (
                            <Container className='twoThird'>
                                <h1 className={styles.homeTitle}>{mainHeaderData.homeTitle}</h1>
                                <p className={styles.homeSubTitle}>
                                    {mainHeaderData.homeSubTitle}
                                </p>

                                <div className={styles.btnGroup}>
                                    <Button buttonClass={['button']} to={RoutesEnum.REGISTER} btnFullWidth>
                                        Register Now
                                    </Button>
                                    <Button buttonClass={['outlinedWhite']} to={RoutesEnum.SOLUTIONS} btnFullWidth>
                                        View Solutions
                                    </Button>
                                </div>
                            </Container>
                        )}
                    { headerText && (
                        <div className={styles.headerTextContainer}>
                            <h4>{headerText}</h4>
                        </div>
                    )}
                </Row>
            </Section>

            { homePageImage && (
                <HomeImage
                    className={styles.homeImage}
                    data-testid='home-image-shape'
                />
            )}
        </div>
    );
};

export default MainHeader;
