import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainLayout from './components/layout/MainLayout';
import MainMenu from './components/layout/MainMenu/MainMenu';
import RoutesEnum from './enums/RoutesEnum';
import ContactUsSection from './pages/ContactUs/ContactUs';
import Home from './pages/home';
import Login from './pages/login';
import './styles/main.scss';

const App = () => (
    <BrowserRouter>
        <MainLayout
            header={(<MainMenu />)}
        >
            <Routes>
                <Route path={RoutesEnum.DEFAULT} element={<Home />} />
                <Route path={RoutesEnum.LOGIN} element={<Login />} />
                <Route path={RoutesEnum.CONTACT} element={<ContactUsSection />} />
            </Routes>
        </MainLayout>
    </BrowserRouter>
);

export default App;
