const versatileData = {
    title: 'Create versatile polymaths to reduce hero-cultures around security',
    description: 'Secure DevOps needs a culture of willingness to take on responsibilities throughout every member of a team. Therefore we provide workflows to skillup in groups.',
    data: [
        {
            key: '1',
            text: 'Define and plan upskilling priorities for your teams',
        },
        {
            key: '2',
            text: 'Create teams in virtual classrooms for specific labs',
        },
        {
            key: '3',
            text: 'Monitor upskilling of your recruits',
        },
        {
            key: '4',
            text: 'Test new applicants and contractors',
        },
        {
            key: '5',
            text: 'Explore new best practices and tools in sandboxes',
        },
    ],
};

export default versatileData;
