import {
    createContext,
    FC,
    useContext,
} from 'react';
import ApiService from '../services/ApiService';

const ApiServiceContext = createContext<ApiService | null>(null);

const useApiService = () => useContext(ApiServiceContext);

interface IApiServiceProviderProps {
    apiService: ApiService;
}

const ApiServiceProvider: FC<IApiServiceProviderProps> = ({
    children,
    apiService,
}) => (
    <ApiServiceContext.Provider value={apiService}>
        {children}
    </ApiServiceContext.Provider>
);

export { useApiService, ApiServiceProvider };
export default ApiServiceContext;
