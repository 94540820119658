import { FC, ReactNode } from 'react';
import styles from './Row.module.scss';

export interface IRowProps{
    className?: 'center',
    children: ReactNode
}

const Row: FC<IRowProps> = ({ className = '', children }) => (
    <div className={`${styles.base} ${styles[className]}`} data-testid='row'>
        {children}
    </div>
);

export default Row;
