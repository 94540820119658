import axios, { AxiosInstance } from 'axios';

const createHttpClient = (baseURL: string): AxiosInstance => axios.create({
    baseURL,
    timeout: 120000,
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
    },
});

export default createHttpClient;
