import RoutesEnum from '../../../../enums/RoutesEnum';

const menu = [
    { to: RoutesEnum.ABOUT_US, label: 'About us' },
    { to: RoutesEnum.FELLOWSHIP, label: 'Fellowship' },
    { to: RoutesEnum.SOLUTIONS, label: 'Solutions' },
    { to: RoutesEnum.FOR_BUSINESS, label: 'For business' },
    { to: RoutesEnum.NEWS, label: 'News' },
    { to: RoutesEnum.CAREERS, label: 'Careers' },
];

export default menu;
