import RoutesEnum from '../../../../enums/RoutesEnum';

const accordionMenus = [
    {
        id: 1,
        summary: 'MENU',
        details: [{
            id: 1,
            text: 'About us',
            link: RoutesEnum.ABOUT_US,
        },
        {
            id: 2,
            text: 'Fellowship',
            link: RoutesEnum.FELLOWSHIP,
        },
        {
            id: 3,
            text: 'Solutions',
            link: RoutesEnum.SOLUTIONS,
        },
        {
            id: 4,
            text: 'For business',
            link: RoutesEnum.FOR_BUSINESS,
        },
        {
            id: 5,
            text: 'News',
            link: RoutesEnum.NEWS,
        },
        {
            id: 6,
            text: 'Careers',
            link: RoutesEnum.CAREERS,
        }],
    },
    {
        id: 2,
        summary: 'PLATFORM',
        details: [{
            id: 1,
            text: 'Courses',
            link: RoutesEnum.COURSES,
        },
        {
            id: 2,
            text: 'Learning paths',
            link: RoutesEnum.LEARNING_PATHS,
        },
        {
            id: 3,
            text: 'Introduction tour',
            link: RoutesEnum.INTRODUCTION_TOUR,
        },
        {
            id: 4,
            text: 'Fellow gallery',
            link: RoutesEnum.FELLOW_GALLERY,
        }],
    },
    {
        id: 3,
        summary: 'ACCOUNT',
        details: [{
            id: 1,
            text: 'Login',
            link: RoutesEnum.LOGIN,
        },
        {
            id: 2,
            text: 'Register',
            link: RoutesEnum.REGISTER,
        },
        {
            id: 3,
            text: 'Contact',
            link: RoutesEnum.CONTACT,
        }],
    },
    {
        id: 4,
        summary: 'PRIVACY',
        details: [{
            id: 1,
            text: 'Terms of use',
            link: RoutesEnum.TERMS_OF_USE,
        },
        {
            id: 2,
            text: 'Privacy policy',
            link: RoutesEnum.PRIVACY,
        },
        {
            id: 3,
            text: 'Cookie policy',
            link: RoutesEnum.COOKIES,
        },
        ],
    },
];

export default accordionMenus;
