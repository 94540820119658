const setToLocalStorage = <T>(key: string, value: T): void => {
    if (value === null || typeof value === undefined) {
        window.localStorage.removeItem(key);
    } else {
        window.localStorage.setItem(
            key,
            typeof value === 'string' ? value : JSON.stringify(value),
        );
    }
};

export default setToLocalStorage;
