import classNames from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import RoutesEnum from '../../../enums/RoutesEnum';
import Carousel from '../../common/carousel/Carousel';
import NewsItem from '../../common/newsItem';
import Subheading from '../../common/subheading';
import Section from '../../layout/Section';
import useNews from './hooks/useNews';
import styles from './NewsSection.module.scss';

const PAGE_SIZE = 3;

const NewsSection : FC = () => {
    const { news, isLoading } = useNews();

    const allArticles = news.academyNews.concat(news.communityNews);

    return (
        <Section sectionStyleClass={['margin40']}>
            <div className={styles.section}>
                <Carousel
                    className={styles.carousel}
                    header={(
                        <div className={styles.header}>
                            <Subheading>
                                News
                            </Subheading>
                            <h3 className={styles.title}>
                                Catch the latest news
                            </h3>
                            <Link
                                className={classNames(styles.button, styles.tablet)}
                                to={RoutesEnum.NEWS}
                            >
                                Check all posts
                            </Link>
                        </div>
                    )}
                >
                    {isLoading ? null : allArticles.map((item, index) => {
                        if (index >= PAGE_SIZE * 2) return null;
                        return (
                            <NewsItem
                                image={item.image}
                                category='Academy'
                                title={item.title}
                                summary={item.summary}
                                link={`${RoutesEnum.NEWS_POST}/${item.slug}`}
                            />
                        );
                    })}
                </Carousel>
                <Link
                    className={classNames(styles.button, styles.mobile)}
                    to={RoutesEnum.NEWS}
                >
                    Check all posts
                </Link>
            </div>
        </Section>
    );
};

export default NewsSection;
