import axios from 'axios';
import RoutesEnum from '../../../enums/RoutesEnum';
import clearLocalStorage from '../../common/utils/clearLocalStorage';
import TokenService from '../../token/services/TokenService';
import appendToken from '../utils/appendToken';
import SessionApiService from '../services/SessionApiService';
import IResponseInterceptors from '../../api/interfaces/IResponseInterceptors';

const createResponseInterceptors = (
    tokenService: TokenService,
    sessionApiService: SessionApiService,
): IResponseInterceptors => ({
    onFulfilled: response => response,
    onRejected: async error => {
        if (error.response) {
            const userRefreshToken = tokenService.refreshToken;
            switch (error.response.status) {
            case 401: // Unauthorized
                if (!userRefreshToken) {
                    clearLocalStorage();
                    tokenService.clear();
                    window.location.href = RoutesEnum.LOGIN;
                } else {
                    try {
                        const newTokens = await sessionApiService.refreshToken(userRefreshToken);
                        // eslint-disable-next-line no-param-reassign
                        tokenService.token = newTokens.token;
                        // eslint-disable-next-line no-param-reassign
                        tokenService.refreshToken = newTokens.refreshToken;
                        return await axios.request(appendToken(error.config, newTokens.token));
                    } catch (e) {
                        clearLocalStorage();
                        tokenService.clear();
                        window.location.href = RoutesEnum.LOGIN;
                    }
                }
                break;
            default:
                break;
            }
        }
        return error;
    },
});

export default createResponseInterceptors;
