import PropTypes from 'prop-types';
import React from 'react';
import styles from './Placeholder.module.scss';

const Placeholder = ({ className, children }) => (
    <div
        className={styles[className]}
        data-testid='placeholder'
    >
        {children}
    </div>
);

Placeholder.propTypes = {
    className: PropTypes.oneOf(['contactAvatarPlaceholder']).isRequired,
    children: PropTypes.node.isRequired,
};

export default Placeholder;
