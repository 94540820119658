import { createContext, FC, useContext } from 'react';
import IConfig from '../interfaces/IConfig';

const ConfigContext = createContext<IConfig>({});

export interface IConfigProviderProps{
  config: IConfig;
}

export const ConfigProvider : FC<IConfigProviderProps> = ({ config, children }) => (
    <ConfigContext.Provider value={config}>
        {children}
    </ConfigContext.Provider>
);

export const useConfig = () => useContext(ConfigContext);

export default ConfigContext;
