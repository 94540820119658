import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import styles from './Subheading.module.scss';

export interface ISubheadingProps{
  children?: ReactNode,
  className?: string,
  size? : 'small' | 'medium'
}

const Subheading: FC<ISubheadingProps> = ({
    children = null,
    className,
    size = 'small',
}) => (
    <p
        role='heading'
        aria-level={7}
        className={classNames(
            styles.subheading,
            {
                [styles.small]: size === 'small',
                [styles.medium]: size === 'medium',
            },
            className,
        )}
    >
        {children}
    </p>
);

export default Subheading;
