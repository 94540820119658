import React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
import FiberManualRecordSharpIcon from '@mui/icons-material/FiberManualRecordSharp';
import styles from './ListElement.module.scss';

const EverableListItem = ({
    listData, listItemStyle, shapeIcon,
}) => {
    const listStyle = listItemStyle.map(item => `${styles[item]}`).join(' ');

    return (
        <List
            dense
        >
            {listData.map(item => (
                <ListItem
                    disablePadding
                    className={`${styles.listItem} ${listStyle}`}
                    key={item.key}
                >
                    <ListItemIcon
                        className={styles.iconContainer}
                        data-testid='listItemIcon'
                    >
                        {shapeIcon === 'checkCircleOutlineIcon' && <CheckCircleOutlineSharpIcon className={styles.listIcon} data-testid='outlinedCircle' />}
                        {shapeIcon === 'checkCircleSharpIcon' && <CheckCircleSharpIcon className={styles.listIcon} data-testid='sharpCircle' />}
                        {shapeIcon === 'bulletCircleSharpIcon' && <FiberManualRecordSharpIcon className={styles.bulletIcon} data-testid='bulletCircle' />}
                    </ListItemIcon>
                    <ListItemText
                        className={styles.listText}
                        primary={item.text}
                    />
                </ListItem>
            ))}
        </List>

    );
};
EverableListItem.propTypes = {
    listData: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired,
    })).isRequired,
    listItemStyle: PropTypes.arrayOf(
        PropTypes.oneOf(['listItem', 'primaryBackground', 'zeroLeftPadding', 'mediumVerticalPadding', 'smallVerticalPadding']), // It only accepts listItemStyle declared in the stylesheet
    ),
    shapeIcon: PropTypes.oneOf(['noCircle', 'checkCircleOutlineIcon', 'checkCircleSharpIcon', 'bulletCircleSharpIcon']),

};
EverableListItem.defaultProps = {
    listItemStyle: ['listItem'],
    shapeIcon: 'noCircle',
};

export default EverableListItem;
