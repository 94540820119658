import { useState } from 'react';
import classNames from 'classnames';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import RoutesEnum from '../../../enums/RoutesEnum';
import Logo from '../../common/logo/Logo';
import NavMenu from '../../common/navMenu/NavMenu';
import CustomLink from '../../common/link/Link';
import EverableButton from '../../common/button/Button';
import Row from '../Row';
import Section from '../Section';
import MobileMenu from './MobileMenu';
import links from './constants/links';
import { ReactComponent as LeftShape } from '../MainHeader/assets/left-shape.svg';
import styles from './MainMenu.module.scss';

const MainMenu = () => {
    const [expandNavBar, setExpandNavBar] = useState(false);

    const handleNavBarOpen = () => setExpandNavBar(true);
    const handleNavBarClose = () => setExpandNavBar(false);

    const mobileVersionSticky = expandNavBar ? 'mobileVersionSticky̦' : 'sticky';
    return (
        <Section className='fullbleed' sectionStyleClass={[`${mobileVersionSticky}`]} gradientBlack>
            <Section>
                {
                    expandNavBar
                    && (
                        <LeftShape
                            className={styles.leftShape}
                            data-testid='header-left-shape'
                        />
                    )
                }
                <Row className="mobile-version">
                    <a href='/' data-testid='logo-link' className={styles.logoName}>
                        <Logo />
                    </a>
                    <NavMenu links={links} />
                    <div className={styles.btnGroup}>
                        <CustomLink href={RoutesEnum.LOGIN}>
                            Login
                        </CustomLink>
                        <EverableButton buttonClass={['medium']} to={RoutesEnum.REGISTER}>
                            Register Now
                        </EverableButton>
                    </div>
                    {!expandNavBar && <MenuIcon role='button' className={styles.openMenuBar} onClick={handleNavBarOpen} data-testid='open-menu' /> }
                    {expandNavBar && <CloseIcon role='button' className={styles.closeMenuBar} onClick={handleNavBarClose} data-testid='close-menu' /> }
                </Row>
                {expandNavBar && (
                    <div
                        data-testid='expanded-menu'
                        className={classNames(
                            styles.expandedMenu,
                        )}
                    >
                        <MobileMenu links={links} />
                        <div
                            data-testid='mobile-btn-group'
                            className={styles.mobileBtnGroup}
                        >
                            <CustomLink className={styles.loginBtn} href={RoutesEnum.LOGIN}>
                                Login
                            </CustomLink>
                            <EverableButton buttonClass={['medium']} to={RoutesEnum.REGISTER}>
                                Register Now
                            </EverableButton>
                        </div>
                    </div>
                )}

            </Section>

        </Section>
    );
};
export default MainMenu;
