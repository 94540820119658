import { AxiosResponse } from 'axios';

function statusChecker<T>(response: AxiosResponse<T>): AxiosResponse<T> {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }

    throw new Error(JSON.stringify(response.data));
}

export default statusChecker;
