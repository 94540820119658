import { FC } from 'react';
import styles from './ErrorMessage.module.scss';

export interface IErrorMessageProps{
  show?: boolean;
}

const ErrorMessage : FC<IErrorMessageProps> = ({
    show,
    children,
}) => (
    show
        ? (
            <p className={styles.error}>
                {children}
            </p>
        )
        : null
);

export default ErrorMessage;
