import PropTypes from 'prop-types';
import Subheading from '../../../../components/common/subheading';
import ContactInfoItem from '../ContactInfoItem';
import styles from './ContactInfo.module.scss';

const ContactInfo = ({ contacts }) => (
    <>
        <div className={styles.textContainer} data-testid='text-container'>
            <Subheading>
                CONTACT US
            </Subheading>
            <h4 className={styles.title} data-testid='text-container-header'>
                Want to get in touch? We would love to hear from you.
            </h4>
            <p className={styles.text} data-testid='text-container-text'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla tincidunt eleifend ligula
                quis semper. Integer nec tellus erat. Nullam non mauris iaculis, pretium lacus eu, elementum
                ipsum. Cras luctus, urna sit amet cursus pretium, magna ante pretium dolor, et sodales justo
                ligula ut ligula.
            </p>
        </div>
        <div className={styles.contactContainer} data-testid='contact-container'>
            {
                contacts.map(contact => (
                    <ContactInfoItem
                        key={contact.id}
                        image={contact.image}
                        imageDescription={contact.imageDescription}
                        position={contact.position}
                        email={contact.email}
                        phone={contact.phone}
                    />
                ))
            }
        </div>
    </>
);

ContactInfo.propTypes = {
    contacts: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        image: PropTypes.string,
        imageDescription: PropTypes.string,
        position: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
    })).isRequired,
};

export default ContactInfo;
