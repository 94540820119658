import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveIcon from '@mui/icons-material/Remove';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styles from './Accordion.module.scss';

const SimpleAccordion = ({ arrItem, className }) => {
    const [expanded, setExpanded] = useState(false);

    const preventDefault = event => event.preventDefault();

    const handleChange = id => (event, isExpanded) => {
        setExpanded(isExpanded ? id : false);
    };

    const renderIcon = id => {
        if (className === 'variant2') {
            return (expanded === id
                ? <RemoveIcon />
                : <AddIcon />
            );
        }
        if (className === 'variant3') {
            return (<ExpandMoreIcon />);
        }
        return null;
    };

    const renderContent = item => {
        if (className === 'variant1' || className === 'variant2') {
            return (
                <h6 className={styles.simple_text} data-testid='accordion_simple_text'>
                    {item.details}
                </h6>
            );
        }
        if (className === 'variant3') {
            return (
                <Box
                    onClick={preventDefault}
                >
                    {item.details.map(detail => (
                        <Link
                            href={detail.link}
                            className={styles.link_text}
                            key={detail.id}
                            data-testid='accordion_menu_text'
                        >
                            {detail.text}
                        </Link>
                    ))}
                </Box>
            );
        }
        return null;
    };

    return (
        <div className={`${styles.wrapper} ${styles[className]}`}>
            {
                arrItem?.map(item => (
                    <Accordion
                        key={item.id}
                        className={styles.item}
                        disableGutters
                        expanded={expanded === item.id}
                        onChange={handleChange(item.id)}
                        data-testid="accordion"
                    >
                        <AccordionSummary
                            className={`${styles.summary} ${expanded === item.id && styles.active}`}
                            expandIcon={renderIcon(item.id)}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <h6
                                className={`${styles.summary_title} ${expanded === item.id ? styles.active : ''}`}
                                data-testid='accordion_title'
                            >
                                {item.summary}
                            </h6>
                        </AccordionSummary>
                        <AccordionDetails className={styles.summary}>
                            {renderContent(item)}
                        </AccordionDetails>
                    </Accordion>
                ))
            }
        </div>
    );
};

export default SimpleAccordion;

SimpleAccordion.propTypes = {
    arrItem: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        summary: PropTypes.string.isRequired,
        details: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                text: PropTypes.string.isRequired,
                link: PropTypes.string.isRequired,
            }).isRequired),
        ]),
    })).isRequired,
    className: PropTypes.oneOf(['variant1', 'variant2', 'variant3']).isRequired,
};
