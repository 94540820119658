import classNames from 'classnames';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Row from '../Row';
import Section from '../Section';
import Container from '../Container';
import Logo from '../../common/logo/Logo';
import Accordion from '../../common/accordion/Accordion';
import NavLink from '../../common/navLink/NavLink';
import accordionMenus from './constants/accordionMenus';
import privacy from './constants/privacy';
import styles from './Footer.module.scss';
import FooterDesktopMenu from './FooterDesktopMenu';

const footerText = 'Enable your organization to increase both security compliance and productivity of software development';

const Footer = () => (
    <Section>
        <Row>
            <Container className='half'>
                <div>
                    <Logo textColor='black' type='full' />
                    <p className={styles.footerText} data-testid='left-content'>
                        Skill up your teams on Secure DevOps
                        <br />
                        {footerText}
                    </p>
                    <Link className={classNames(styles.footerLink)} href='/' underline="hover" data-testid='contact-mail'>
                        info@everable.com
                    </Link>
                </div>
            </Container>
            <FooterDesktopMenu data-testid='footer-menu' />
            <Accordion arrItem={accordionMenus} className='variant3' data-testid='footer-accordion' />

        </Row>
        <Divider className={classNames(styles.footerDivider)} data-testid='divider' />
        <Row className='fellowSection-37padding'>
            <Container className='half'>
                <p className={styles.copyRight} data-testid='copy-right'>
                    © Copyright Everable 2022
                </p>
            </Container>
            <Container className='half'>
                <div className={styles.privacy} data-testid='privacy-menu'>
                    {privacy.map(item => <NavLink key={`${item.to}${item.label}`} to={item.to} label={item.label} className='privacy-footer' />)}
                </div>
            </Container>
        </Row>
    </Section>
);

export default Footer;
