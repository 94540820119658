import React from 'react';
import classNames from 'classnames';
import Container from '../Container/Container';
import NavMenu from '../../common/navMenu/NavMenu';
import platform from './constants/platform';
import menu from './constants/menu';
import account from './constants/account';
import styles from './Footer.module.scss';

const FooterDesktopMenu = () => (
    <Container className='halfRowDirection' displayNoneMobile>
        <Container className='third'>
            <p className={classNames(styles.menuTitle)} data-testid='menu-title'>
                Menu
            </p>
            <NavMenu className='text-black' vertical links={menu} />
        </Container>
        <Container className='third'>
            <p className={classNames(styles.menuTitle)} data-testid='menu-title'>
                Platform
            </p>
            <NavMenu className='text-black' vertical links={platform} />
        </Container>
        <Container className='third'>
            <p className={classNames(styles.menuTitle)} data-testid='menu-title'>
                Account
            </p>
            <NavMenu className='text-black' vertical links={account} />
        </Container>
    </Container>
);

export default FooterDesktopMenu;
