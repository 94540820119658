import { AxiosRequestConfig } from 'axios';

const appendToken = (
    config: AxiosRequestConfig,
    token: string | null,
): AxiosRequestConfig => {
    if (token && config.headers) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
};

export default appendToken;
