import CallToActionBanner from '../../components/common/actionBanner/CallToActionBanner';
import MainHeader from '../../components/layout/MainHeader/MainHeader';
import Row from '../../components/layout/Row';
import Section from '../../components/layout/Section';
import contacts from './constants/contacts';
import ContactForm from './components/ContactForm';
import ContactInfo from './components/ContactInfo';
import styles from './ContactUs.module.scss';

const ContactUsSection = () => (
    <Section
        className='fullbleed'
        sectionStyleClass={['contactUsWhite']}
    >
        <MainHeader headerText='Contact us' headerClassName={['small', 'contactUsPage']} />
        <Section>
            <Row>
                <div className={styles.contactUsLeft}>
                    <ContactInfo contacts={contacts} />
                </div>
                <div className={styles.contactUsRight}>
                    <ContactForm />
                </div>
            </Row>
        </Section>
        <Section>
            <div className={styles.contactUsActionBanner}>
                <CallToActionBanner
                    buttonText='Register now'
                    buttonPathName='/'
                >
                    Start the free introduction tour to determine
                    Secure DevOps learning paths that benefit your teamss
                </CallToActionBanner>
            </div>
        </Section>
    </Section>
);

export default ContactUsSection;
