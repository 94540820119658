const breakPoints = [
    {
        width: 1,
        itemsToShow: 1,
    },
    {
        width: 320,
        itemsToShow: 2,
        itemsToScroll: 2,
    },
    {
        width: 550,
        itemsToShow: 2,
        itemsToScroll: 2,
    },
    {
        width: 768,
        itemsToShow: 3,
        pagination: false,
        enableMouseSwipe: false,
    },
];

export default breakPoints;
