const upSkillingData = {
    title: 'How Everable upskilling is effective for your teams',
    data: [
        {
            id: 1,
            summary: 'Security meets productivity',
            details: 'Every hands-on lab is around a use case to gain delivery speed while improving control over security.',
        },
        {
            id: 2,
            summary: 'Concise skillbuilding',
            details: 'As in this space everybody is busy, we enable teams to gain skills during a long coffeebreak. Our hands-on labs are short and precise.',
        },
        {
            id: 3,
            summary: 'Direct feedback',
            details: 'Automated checking and feedback enhances upskilling effect and enables students to execute labs whenever it suits them.',
        },
    ],
};

export default upSkillingData;
