import { FC } from 'react';
import { Link } from 'react-router-dom';
import Chip from '../chip';
import CustomLink from '../link/Link';
import Subheading from '../subheading';
import styles from './NewsItem.module.scss';

export interface INewsItemProps{
  title: string,
  category: string,
  summary?: string,
  link: string,
  image: string
}

const NewsItem : FC<INewsItemProps> = ({
    title,
    category,
    summary,
    link,
    image,
}) => (
    <div className={styles.item}>
        <Link to={link}>
            <img
                className={styles.image}
                src={image}
                alt={`${title}`}
            />
        </Link>
        <Chip>
            {category}
        </Chip>
        <Subheading
            size="medium"
            className={styles.title}
        >
            {title}
        </Subheading>
        {summary ? (
            <p className={styles.summary}>
                {summary}
            </p>
        ) : null}
        <CustomLink
            href={link}
        >
            Read more
        </CustomLink>
    </div>
);

export default NewsItem;
