import amitSharma from '../assets/Amit-Sharma2.jpg';
import patrickDronk from '../assets/Patrick-Dronk.jpg';
import rolandSchijvenaars from '../assets/Roland-Schijvenaars.jpg';

const testimonialData = [
    {
        id: 1,
        name: 'Patrick Dronk',
        title: 'Java Software Engineer at Codecentric',
        image: patrickDronk,
        summary: 'Proud to announce I received my first hands-on certificate from Everable! The course was very valuable and provided hands-on knowledge that I applied directly in my daily work. Looking forward to more courses!',
    },
    {
        id: 2,
        name: 'Roland Schijvenaars',
        title: 'Product Owner Azure DevOps at ABN AMRO',
        image: rolandSchijvenaars,
        summary: 'I am pleased to say I have completed one of the online hand-on Secrets Management trainings­ provided by Everable. Looking forward to doing more of this kind of hands-on trainings!',
    },
    {
        id: 3,
        name: 'Amit Sharma',
        title: 'Security Expert at Synopsis',
        image: amitSharma,
        summary: 'Secrets Management, my favourite area! A pleasant experience with a very good platform and great content. The hands on lab is really cool. It tests understanding and provides you with hints if you are stuck.',
    },
];

export default testimonialData;
