import getFromLocalStorage from '../../common/utils/getFromLocalStorage';
import setToLocalStorage from '../../common/utils/setToLocalStorage';
import LocalStorageKeysEnum from '../enums/LocalStorageKeysEnum';

export default class TokenService {
    private _tokenKey: string;

    private _token: string | null;

    private _refreshTokenKey: string;

    private _refreshToken: string | null;

    constructor(
        tokenKey: string = LocalStorageKeysEnum.TOKEN,
        refreshTokenKey: string = LocalStorageKeysEnum.REFRESH_TOKEN,
    ) {
        this._tokenKey = tokenKey;
        this._token = getFromLocalStorage(this._tokenKey);
        this._refreshTokenKey = refreshTokenKey;
        this._refreshToken = getFromLocalStorage(this._refreshTokenKey);
    }

    get token(): string | null {
        if (!this._token) {
            this._token = getFromLocalStorage(this._tokenKey);
        }
        return this._token;
    }

    set token(value: string | null) {
        this._token = value;
        setToLocalStorage(this._tokenKey, value);
    }

    get refreshToken(): string | null {
        if (!this._refreshToken) {
            this._refreshToken = getFromLocalStorage(this._refreshTokenKey);
        }
        return this._refreshToken;
    }

    set refreshToken(value: string | null) {
        this._refreshToken = value;
        setToLocalStorage(this._refreshTokenKey, value);
    }

    clear() {
        this.token = null;
        this.refreshToken = null;
    }
}
